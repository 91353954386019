import BaseAPI, { URLS } from "./BaseAPI";

class AuctionAPI {
    static _exception(e, message) {
        console.log(e);
        return { error: message };
    }

    /**
     * @param  {Object} data Dados do novo item do lelão.
     */
    static async createAuction(data) {
        try {
            const url = `${URLS.AUCTIONS}`;
            const response = await BaseAPI.post(url, data );
            return response.status === 201 ? response : {error: "Falha ao criar item do leilão."};
        } catch (e) {
            console.log(e.message);
            return { error: 'Falha ao criar item do leilão.' }
        }
    }

    /**
     * @param  {Object} uuid uuid do lelão.
     */
    static async getAuctionByUuid(uuid) {
        try {
            const url = `${URLS.AUCTIONS}/${uuid}`;
            const response = await BaseAPI.get(url);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar leilão.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    /**
     * Busca todos os leilões
     */
    static async getAuctions(page, size) {
        try {
            const url = `${URLS.AUCTIONS}?page=${page}&size=${size}`;
            const response = await BaseAPI.get(url);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar leilões.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    /**
     * Busca todos os leilões ativos
     */
    static async getAuctionsActive() {
        try {
            const url = `${URLS.AUCTIONS}/active`;
            const response = await BaseAPI.get(url);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar leilão.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    /**
     *@param  {Object} uuid Busca leilão ativo por uuid
     */
    static async getAuctionUuidActive(uuid) {
        try {
            const url = `${URLS.AUCTIONS}/active/${uuid}`;
            const response = await BaseAPI.get(url);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar leilão.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async changeStatus(uuid, status) {
        try {
            const url = `${URLS.AUCTIONS}/status/${uuid}?status=${status}`;
            const response = await BaseAPI.put(url)
            if(response.status === 200) return response.data;
            return { error: 'Falha ao mudar status.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async changeActiveStatusAuction(uuid, status) {
        try {
            const url = `${URLS.AUCTIONS}/active/${uuid}?active=${status}`
            const response = await BaseAPI.put(url)
            if(response.status===200) return response.data;
            return {error: "Falha ao alterar status do leilão."}

        } catch (e){
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async desactiveAuction(uuid) {
        try {
            const url = `${URLS.AUCTIONS}/active/${uuid}?active=false`
            const response = await BaseAPI.put(url)
            if(response.status===200) return response.data;
            return {error: "Falha ao ativer leilão."}

        } catch (e){
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async createBidMessage (uuid, data) {
        try {
            const url = `${URLS.AUCTIONS}/action/${uuid}`
            const response = await BaseAPI.post(url, data);
            if(response.status === 200) return response.data;
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

   static async listByType(search) {
     try {
       const url = `${URLS.AUCTIONS}/search?${search}`;
       const response = await BaseAPI.get(url);
       if (response.status === 200) return response.data;
       return { error: 'Erro inesperado ao buscar leilões' };
     } catch (e) {
       return this._exception(e, 'Falha ao listar leilões');
     }
   }

    static async recreatePayment (uuid) {
        try {
            const url = `${URLS.AUCTIONS}/new/${uuid}?status=FINISHED`
            const response = await BaseAPI.put(url);
            if(response.status === 200) return response.data;
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    /**
     * @param  {Object} data Dados do status do lelão.
     */
    static async auctionStatusConfig(data) {

        try {
            const url = `${URLS.AUCTIONS}/config`;
            const response = await BaseAPI.post(url, data );
            return response.status === 200 ? response.data : {error: "Falha ao criar configurações do leilão."};
        } catch (e) {
            console.log(e.message);
            return { error: 'Falha ao configurar leilão.' }
        }
    }

    static async getAucionInfos() {
        try {
            const url = `${URLS.AUCTIONS}/config`;
            const response = await BaseAPI.get(url);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar informações do leilão.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async activeAuctionLive (uuid) {
        try {
            const url = `${URLS.AUCTIONS}/live/${uuid}`
            const response = await BaseAPI.put(url);
            if(response.status === 200) return response.data;
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async getAuctionReportByDate(date){
        try {
            const url = `${URLS.AUCTIONS}/recent?date=${date}`;
            
            const response = await BaseAPI.get(url);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar relatório.' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

}
export default AuctionAPI;
